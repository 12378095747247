html {
  font-family: splunk_data_sans,Helvetica,Arial,sans-serif !important;
}

a {
  color: #ed0080;
}
a:visited{
  color: #ed0080;
}

.splunk-pink {
  color:  #ed0080;
}

.elevate {
  background: linear-gradient(-60deg,#f99d1c,#f99c1c 10%,#f4781f 30%,#f16221 43%,#f05a22 58%,#ee1d62 76%,#ed0080 97%);
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: linear-gradient(-60deg, rgb(249, 157, 28), rgb(249, 156, 28) 10%, rgb(244, 120, 31) 30%, rgb(241, 98, 33) 43%, rgb(240, 90, 34) 58%, rgb(238, 29, 98) 76%, rgb(237, 0, 128) 97%);
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App {  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Mui-checked {
  color: "#ed0080";
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.pink-button {
  color: #ed0080 !important;
  border-color: #ed0080 !important;
}

.pink-button:hover {
  background-color:rgba(144, 202, 249, 0.055) !important;
  border-color: #f16221 !important;
  color: #f16221 !important;
}