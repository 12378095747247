@font-face {
  font-family: "splunk_data_sans";
  src: local('splunk_data_sans'), url("./fonts/splunkdatasans_w_rg.woff2") format('woff2')
}

body {
  margin: 0;
  font-family: splunk_data_sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}